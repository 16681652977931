export const Resume = () => {
    return (

        <>
        
        <div className="container" id="resume" style={{paddingTop: 100, paddingBottom: 100}}>
            <div className="row">
                <div className="col">
                    <h1 style = {{textAlign: 'center', textDecoration: 'underline'}}> RESUME </h1>
                    <img className="img-fluid" src="images/Resume.png" alt="resume" style={{ boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.75)' }}/>
                </div>
            </div>
        </div>
        
        </>


    )


}