export const List = () => {
    return (

        <>
    
        <div className="card col" style={{margin:'10px', height:'100%'}}>
            <img src="images/task.png" alt="" className="card-img-top" style={{width:'auto', height:'auto', display:'block'}}/>
            <div className="card-body">
                <h5 className="card-tite">TaskBuddy</h5>
                <p className="card-text">A Todo list website for students & Acts as a study aid</p>
                <div class="card-footer">
                    <small class="text-muted">Last updated: Work in progress</small>
                </div>
            </div>
        </div>

        </>
    )



}